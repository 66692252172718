<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" modal-class="event-type-modal-anchor"
      @hidden="cancelModal"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
        <ul :show="showErrorDetail" class="mb-0">
          <template v-for="(item, index) in alertMsgDetails">
            <li :key="index">{{ item }}</li>
          </template>
        </ul>
      </b-alert>

      <div class="d-block">
        <b-tabs content-class="mt-3 tab-container" class="event-type" v-model="typeTab" pills>
          <b-tab v-if="isBookingVisible" :title="$t('staff.group.booking')">
            <div v-if="isBookingAccessDenied" class="modal-message-overlay">
              <span class="grid-overlay">
                {{ 
                bookingRestrictedRequiredField != null
                  ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('booking.title_singular').toLowerCase(), bookingRestrictedRequiredField])
                  : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('booking.title_singular').toLowerCase()])
                }}
                </span>
            </div>
            <div v-else class="container pl-0">
              <b-row>
                <b-col cols="12" class="pr-0">
                  <b-form-group>
                    <label class="mr-1">{{ $t(`booking.field.project`) }}</label>
                    <button id="BOOKING_PROJECT_ADD" class="btn-action" @click="projectSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="BOOKING_PROJECT_ADD"
                        placement="top"
                        triggers="hover"
                        boundary="viewport"
                        :content="$t('booking.button.project_add')">
                      </b-popover>
                    </button>
                  
                    <BadgeGroup v-model="newEvent.projectBooking">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="projectBookingBadgeRemove(index)" @badgeClick="projectBookingBadgeClick(item.uuId)"
                          :text="`${item.name}`" 
                          :variant="'primary'"
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>

                <template v-if="customBookingFieldMap['projects'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['projects'].filter(i => i.notNull == true)" :key="'projects'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>

                <template v-if="customBookingFieldMap['default'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['default'].filter(i => i.notNull == true)" :key="index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
                
                <b-col v-if="isDescriptionVisible" cols="12" class="pr-0">
                  <b-form-group :label="$t('booking.field.description')" label-for="booking-description">
                    <b-form-textarea id="booking-description" 
                      :placeholder="$t('booking.placeholder.description')"
                      v-model="newEvent.description"
                      :max-rows="6"
                      trim
                      :rows="3"/>
                  </b-form-group> 
                </b-col>
                <template v-if="customBookingFieldMap['description'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['description'].filter(i => i.notNull == true)" :key="'description'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
            
                <b-col cols="12" xl="6" class="pr-0">
                  <b-row class="mb-3">
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.startTime')" label-for="task-startDate">
                        <b-form-datepicker v-model="newEvent.startDate" class="mb-2" @input="dateChanged('startDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <Timepicker v-model="newEvent.startTime" locale="en"  @input="dateChanged('startTime')">
                      </Timepicker>
                    </b-col>
                  </b-row>
                </b-col>
                <template v-if="customBookingFieldMap['startTime'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['startTime'].filter(i => i.notNull == true)" :key="'startTime'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
      
                <b-col cols="12" xl="6" class="pr-0">
                  <b-row class="mb-3">
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.closeTime')" label-for="task-closeDate">
                        <b-form-datepicker v-model="newEvent.closeDate" class="mb-2"  @input="dateChanged('closeDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <Timepicker v-model="newEvent.closeTime" locale="en"  @input="dateChanged('closeTime')">
                      </Timepicker>
                    </b-col>
                  </b-row>
                </b-col>
                <template v-if="customBookingFieldMap['closeTime'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['closeTime'].filter(i => i.notNull == true)" :key="'closeTime'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>

                <b-col cols="12" sm="6" class="pr-0" v-if="isStageVisible">
                  <b-form-group id="field-stage" :label="$t('task.field.stage')" label-for="task-stage">
                      <b-form-select id="task-stage" v-model="bookingStage" 
                        :options="optionBookingStage" 
                        :disabled="isStageReadOnly"
                        text-field="label" 
                        value-field="key"/>
                  </b-form-group>
                </b-col>
                <template v-if="customBookingFieldMap['stage'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['stage'].filter(i => i.notNull == true)" :key="'stage'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
            
                <b-col cols="12" class="pr-0">
                  <b-form-group v-if="mode === 'resource'">
                    <label class="mr-1">{{ $t(`task.field.resources`) }}</label>
                    <button v-if="canList('RESOURCE')" id="BOOKING_RESOURCE_ADD" class="btn-action" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="BOOKING_RESOURCE_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.resource_add')">
                      </b-popover>
                    </button>
                    <BadgeGroup v-model="resources">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
                          :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ' :''}${item.utilization? item.utilization * 100 : 100}%)`" 
                          variant="primary" 
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
                <template v-if="customBookingFieldMap['resources'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['resources'].filter(i => i.notNull == true)" :key="'resources'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
                 
                <b-col cols="12" class="pr-0">
                  <b-form-group v-if="mode === 'staff'">
                    <label class="mr-1">{{ $t(`activity.field.staffs`) }}</label>
                    <button v-if="canList('STAFF')" id="BOOKING_STAFF_ADD" class="btn-action" @click="staffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="BOOKING_STAFF_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('activity.button.staff_add')">
                      </b-popover>
                    </button>
                  
                    <BadgeGroup v-model="staffs">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="staffBadgeClick(item.uuId)"
                          :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ': ''}${item.utilization? Math.trunc(item.utilization * 100) : 100}%)`" 
                          :variant="item.genericStaff? 'info' : 'primary'"
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
                <template v-if="customBookingFieldMap['staffs'] != null">
                  <b-col v-for="(field, index) in customBookingFieldMap['staffs'].filter(i => i.notNull == true)" :key="'staffs'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
            </div>
          </b-tab>
          <b-tab v-if="isActivityVisible" :title="$t('staff.group.activity')">
            <div v-if="isActivityAccessDenied" class="modal-message-overlay">
              <span class="grid-overlay">
                {{ 
                activityRestrictedRequiredField != null
                  ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('activity.title_singular').toLowerCase(), activityRestrictedRequiredField])
                  : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('activity.title_singular').toLowerCase()])
                }}
                </span>
            </div>
            <div v-else class="container pl-0">
              <b-row>
                <b-col cols="12" class="pr-0">
                  <b-form-group :label="$t('activity.field.name')" label-for="activity-name">
                    <b-form-input id="activity-name" v-model="activityName"></b-form-input>
                  </b-form-group>
                </b-col>
                <template v-if="customActivityFieldMap['name'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['name'].filter(i => i.notNull == true)" :key="'name'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>

                <template v-if="customActivityFieldMap['default'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['default'].filter(i => i.notNull == true)" :key="index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
                  
                <b-col cols="12" class="pr-0" v-if="isDescriptionVisible">
                  <b-form-group :label="$t('activity.field.description')" label-for="activity-description">
                    <b-form-textarea id="activity-description" 
                      :placeholder="$t('activity.placeholder.description')"
                      v-model="newEvent.description"
                      :max-rows="6"
                      trim
                      :rows="3"/>
                  </b-form-group>
                </b-col>
                <template v-if="customActivityFieldMap['description'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['description'].filter(i => i.notNull == true)" :key="'description'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
            
                <b-col cols="12" xl="6" class="pr-0">
                  <b-row class="mb-3">
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.startTime')" label-for="task-startDate">
                        <b-form-datepicker v-model="newEvent.startDate" class="mb-2" @input="dateChanged('startDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <Timepicker v-model="newEvent.startTime" locale="en"  @input="dateChanged('startTime')">
                      </Timepicker>
                    </b-col>
                  </b-row>
                </b-col>
                <template v-if="customActivityFieldMap['startTime'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['startTime'].filter(i => i.notNull == true)" :key="'startTime'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
        
                <b-col cols="12" xl="6" class="pr-0">
                  <b-row class="mb-3">
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.closeTime')" label-for="task-closeDate">
                        <b-form-datepicker v-model="newEvent.closeDate" class="mb-2"  @input="dateChanged('closeDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <Timepicker v-model="newEvent.closeTime" locale="en"  @input="dateChanged('closeTime')">
                      </Timepicker>
                    </b-col>
                  </b-row>
                </b-col>
                <template v-if="customActivityFieldMap['closeTime'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['closeTime'].filter(i => i.notNull == true)" :key="'closeTime'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>

                <b-col cols="12" sm="6" class="pr-0" v-if="isStageVisible">
                  <b-form-group id="field-stage" :label="$t('task.field.stage')" label-for="task-stage">
                      <b-form-select id="task-stage" v-model="activityStage" 
                        :options="optionActivityStage" 
                        :disabled="isStageReadOnly"
                        text-field="label" 
                        value-field="key"/>
                  </b-form-group>
                </b-col>
                <template v-if="customActivityFieldMap['stage'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['stage'].filter(i => i.notNull == true)" :key="'stage'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
              
                <b-col cols="12" class="pr-0"> 
                  <b-form-group v-if="mode === 'resource'">
                    <label class="mr-1">{{ $t(`task.field.resources`) }}</label>
                    <button id="ACTIVITY_RESOURCE_ADD" class="btn-action" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="ACTIVITY_RESOURCE_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.resource_add')">
                      </b-popover>
                    </button>
                    <BadgeGroup v-model="resources">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
                          :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ' :''}${item.utilization? item.utilization * 100 : 100}%)`" 
                          variant="primary" 
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
                <template v-if="customActivityFieldMap['resources'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['resources'].filter(i => i.notNull == true)" :key="'resources'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
                
                <b-col cols="12" class="pr-0"> 
                  <b-form-group v-if="mode === 'staff'">
                    <label class="mr-1">{{ $t(`activity.field.staffs`) }}</label>
                    <button id="ACTIVITY_STAFF_ADD" class="btn-action" @click="staffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="ACTIVITY_STAFF_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('activity.button.staff_add')">
                      </b-popover>
                    </button>
                  
                    <BadgeGroup v-model="staffs">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="staffBadgeClick(item.uuId)"
                          :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ': ''}${item.utilization? Math.trunc(item.utilization * 100) : 100}%)`" 
                          :variant="item.genericStaff? 'info' : 'primary'"
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
                <template v-if="customActivityFieldMap['staffs'] != null">
                  <b-col v-for="(field, index) in customActivityFieldMap['staffs'].filter(i => i.notNull == true)" :key="'staffs'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
            </div>
          </b-tab>
          <b-tab v-if="isTaskVisible" :title="$t('staff.group.task')">
            <div v-if="isTaskAccessDenied" class="modal-message-overlay">
              <span class="grid-overlay">
                {{ 
                taskRestrictedRequiredField != null
                  ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('task.title_singular').toLowerCase(), taskRestrictedRequiredField])
                  : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('task.title_singular').toLowerCase()])
                }}
                </span>
            </div>
            <div v-else class="container pl-0">
              <b-row>
                <b-col cols="12" class="pr-0">
                  <b-form-group :label="$t('task.field.name')" label-for="task-name">
                    <b-form-input id="task-name" v-model="taskName"></b-form-input>
                  </b-form-group>
                </b-col>

                <template v-if="customTaskFieldMap['name'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['name'].filter(i => i.notNull == true)" :key="'name'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>

                <template v-if="customTaskFieldMap['default'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['default'].filter(i => i.notNull == true)" :key="index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
                      
                <b-col cols="12" class="pr-0">
                  <b-form-group>
                    <label class="mr-1">{{ $t(`task.field.project`) }}</label>
                    <button id="TASK_PROJECT_ADD" class="btn-action" @click="projectSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="TASK_PROJECT_ADD"
                        placement="top"
                        triggers="hover"
                        boundary="viewport"
                        :content="$t('task.button.project_add')">
                      </b-popover>
                    </button>
                  
                    <BadgeGroup v-model="newEvent.projectTask">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="projectTaskBadgeRemove(index)" @badgeClick="projectTaskBadgeClick(item.uuId)"
                          :text="`${item.name}`" 
                          :variant="'primary'"
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
                <template v-if="customTaskFieldMap['projects'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['projects'].filter(i => i.notNull == true)" :key="'projects'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
           
                <b-col cols="12" class="pr-0" v-if="isDescriptionVisible">
                  <b-form-group :label="$t('task.field.description')" label-for="task-description">
                    <b-form-textarea id="task-description" 
                      :placeholder="$t('task.placeholder.description')"
                      v-model="newEvent.description"
                      :max-rows="6"
                      trim
                      :rows="3"/>
                  </b-form-group>
                </b-col>
                <template v-if="customTaskFieldMap['description'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['description'].filter(i => i.notNull == true)" :key="'description'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>

                <b-col cols="12" xl="6" class="pr-0">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.startTime')" label-for="task-startDate">
                        <b-form-datepicker v-model="newEvent.startDate" class="mb-2" @input="dateChanged('startDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <Timepicker v-model="newEvent.startTime" locale="en"  @input="dateChanged('startTime')">
                      </Timepicker>
                    </b-col>
                  </b-row>
                </b-col>
                <template v-if="customTaskFieldMap['startTime'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['startTime'].filter(i => i.notNull == true)" :key="'startTime'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
      
                <b-col cols="12" xl="6" class="pr-0">
                  <b-row class="mb-3">
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.closeTime')" label-for="task-closeDate">
                        <b-form-datepicker v-model="newEvent.closeDate" class="mb-2"  @input="dateChanged('closeDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <Timepicker v-model="newEvent.closeTime" locale="en"  @input="dateChanged('closeTime')">
                      </Timepicker>
                    </b-col>
                  </b-row>
                </b-col>
                <template v-if="customTaskFieldMap['closeTime'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['closeTime'].filter(i => i.notNull == true)" :key="'closeTime'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
               
                <b-col cols="12" sm="6" class="pr-0" v-if="isStageVisible">
                  <b-form-group id="field-stage" :label="$t('task.field.stage')" label-for="task-stage">
                      <b-form-select id="task-stage" v-model="taskStage" 
                        :options="optionStage" 
                        :disabled="isStageReadOnly"
                        text-field="label" 
                        value-field="key"/>
                  </b-form-group>
                </b-col>
                <template v-if="customTaskFieldMap['stage'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['stage'].filter(i => i.notNull == true)" :key="'stage'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
            
                <b-col cols="12">
                  <b-form-group v-if="mode === 'resource'">
                    <label class="mr-1">{{ $t(`task.field.resources`) }}</label>
                    <button v-if="canView('RESOURCE')" id="TASK_RESOURCE_ADD" class="btn-action" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="TASK_RESOURCE_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.resource_add')">
                      </b-popover>
                    </button>
                    <BadgeGroup v-model="resources">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
                          :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ' :''}${item.utilization? item.utilization * 100 : 100}%)`" 
                          variant="primary" 
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
                     
                <b-col cols="12">
                  <b-form-group v-if="mode === 'staff'">
                    <label class="mr-1">{{ $t(`activity.field.staffs`) }}</label>
                    <button id="TASK_STAFF_ADD" class="btn-action" @click="staffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="TASK_STAFF_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('activity.button.staff_add')">
                      </b-popover>
                    </button>
                  
                    <BadgeGroup v-model="staffs">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="staffBadgeClick(item.uuId)"
                          :text="`${item.name} (${item.quantity > 1? item.quantity + ' x ': ''}${item.utilization? Math.trunc(item.utilization * 100) : 100}%)`" 
                          :variant="item.genericStaff? 'info' : 'primary'"
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
                <template v-if="customTaskFieldMap['resources'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['resources'].filter(i => i.notNull == true)" :key="'resources'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>

                <template v-if="customTaskFieldMap['staffs'] != null">
                  <b-col v-for="(field, index) in customTaskFieldMap['staffs'].filter(i => i.notNull == true)" :key="'staffs'+index" cols="12" class="pr-0">
                    <b-form-group>
                      <template v-if="field.type !== 'Boolean'" slot="label">
                        <span class="mr-2">{{ field.displayName }}</span>
                        <span v-if="field.description">
                          <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                          <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                            {{ field.description }}
                          </b-popover>  
                        </span>
                      </template>
                      <CustomField v-model="newEvent.customFields[field.name]" :componentId="componentId" :field="field"></CustomField>
                    </b-form-group>
                  </b-col>
                </template>
                
              </b-row>
            </div>
          </b-tab>
          <b-tab v-if="isVacationVisible" :title="$t('staff.group.vacation')">
            <div class="container pl-0">
              <b-row>
                <b-col cols="12" class="pr-0">
                  <b-form-group :label="$t('vacation.field.name')" label-for="vacation-name">
                    <b-form-input id="vacation-name" v-model="vacationName"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" xl="6" class="pr-0">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.startTime')" label-for="task-startDate">
                        <b-form-datepicker v-model="newEvent.startDate" class="mb-2" @input="dateChanged('startDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
        
                <b-col cols="12" xl="6" class="pr-0">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('task.field.closeTime')" label-for="task-closeDate">
                        <b-form-datepicker v-model="newEvent.closeDate" class="mb-2"  @input="dateChanged('closeDate')"
                          today-button
                          reset-button
                          close-button
                          hide-header
                          :label-today-button="$t('date.today')"
                          :label-reset-button="$t('date.reset')"
                          :label-close-button="$t('date.close')"
                          today-button-variant="primary"
                          reset-button-variant="danger" 
                          close-button-variant="secondary"
                        >
                          <template v-slot:button-content="{ }">
                            <font-awesome-icon :icon="['far', 'calendar-days']" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                
                <b-col cols="12" class="pr-0">
                  <b-form-group>
                    <label class="mr-1">{{ $t(`activity.field.staffs`) }}</label>
                    <button id="VACATION_STAFF_ADD" class="btn-action" @click="staffSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="VACATION_STAFF_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('activity.button.staff_add')">
                      </b-popover>
                    </button>
                  
                    <BadgeGroup v-model="staffs">
                      <template v-slot:default="{ item, index }">
                        <Badge @badgeRemove="staffBadgeRemove(index)" @badgeClick="vacationStaffBadgeClick(item.uuId)"
                          :text="`${item.name}`" 
                          :variant="item.genericStaff? 'info' : 'primary'"
                          :pillable="!!item.pillable" :key="index" />
                        </template>
                    </BadgeGroup>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
          
      </div>
      
      <template v-slot:modal-footer="{ }">
        <b-button :disabled="dataNotReady" v-if="!isAccessDenied" size="sm" variant="success" @click="handleOkBtnClicked">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancelModal">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- staff selector -->
    <StaffSelectorModalForAdmin v-if="state.staffSelectorShow"
      :show.sync="state.staffSelectorShow"
      :projectIds="staffProjectIds" 
      nonAdmin
      @ok="staffSelectorOk"
    />
    <!-- resource selector -->
    <GenericSelectorModalForAdmin v-if="state.resourceSelectorShow"
      :show.sync="state.resourceSelectorShow" 
      :entityService="resourceUtil"
      :projectIds="staffProjectIds"
      entity="RESOURCE"
      nonAdmin
      singleSelection
      @ok="resourceSelectorOk"
    />
    <!-- project selector -->
    <GenericSelectorModalForAdmin v-if="state.projectSelectorShow"
      :show.sync="state.projectSelectorShow" 
      :entityService="projectUtil" 
      entity="PROJECT"
      nonAdmin
      singleSelection
      @ok="projectSelectorOk"
    />
    <StaffUtilizationModal v-if="state.staffUtilizationEditShow" :supportsUnit="typeTab !== bookingIndex" :show.sync="state.staffUtilizationEditShow" :uuId="staffUtilizationEdit.uuId" :name="staffUtilizationEdit.name" :utilization="staffUtilizationEdit.utilization" :unit="staffUtilizationEdit.unit" :genericStaff="staffUtilizationEdit.genericStaff" @ok="staffUtilizationOk"/>
    <ResourceUnitModal v-if="state.resourceUnitEditShow" :show.sync="state.resourceUnitEditShow" :uuId="resourceUnitEdit.uuId" :name="resourceUnitEdit.name" :unit="resourceUnitEdit.unit" :utilization="parseFloat(resourceUnitEdit.utilization)" @ok="resourceUnitOk"/>
    
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

import { cloneDeep } from 'lodash';
import { 
  convertDate, 
  processRegExp,
  processCalendar,
  transformCalendar 
} from '@/helpers';
import { projectService, stageService, staffService } from '@/services';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { resourceUtil } from '@/views/management/script/resource';
import { projectUtil } from '@/views/management/script/project';

export default {
  name: 'EventTypeModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    StaffUtilizationModal: () => import('@/components/modal/StaffUtilizationModal'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal'),
    Timepicker: () => import('@/components/Calendar/Timepicker.vue'),
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    event:     { type: Object,   default: null },
    title:     { type: String,   default: null },
    show:      { type: Boolean, required: true },
    mode:      { type: String,   default: 'staff'},
    profileValues: { type: Object, default: null }
  },
  data() {
    return {
      propShowFlipCount: 0,
      permissionName: "ACTIVITY",
      modelInfo: null,
      alertError: false,
      alertMsg: null,
      alertMsgDetails: [],
      settings: {},
      state: {
        modalShow:                false,
        projectSelectorShow:      false,
        staffSelectorShow:        false,
        resourceSelectorShow:     false,
        staffUtilizationEditShow: false,
        resourceUnitEditShow:     false
      },
      bookingStage: null,
      activityStage: null,
      taskStage: null,
      project: {
        bookingStageList: [],
        taskStageList: []
      },
      activityStageList: [],
      newEvent: {
        description: null,
        projectBooking: [],
        projectTask: [],
        customFields: {}
      },
      staffs: [
        // { uuId: "1", name: "John", resourceLink: { } }
      ],
      resources: [],
      optionBookingStage: [],
      optionActivityStage: [],
      optionStage: [],
      typeTab: 0,
      duration: 0,
      activityName: null,
      taskName: null,
      vacationName: null,
      staffUtilizationEdit: {
        uuId:  null,
        name:  null,
        utilization: null,
        unit: null,
        genericStaff: false
      },
      resourceUnitEdit: {
        uuId: null,
        name: null,
        unit: null,
        utilization: null
      },
      
      activity: {
        customFields: []
      },
      booking: {
        customFields: []
      },
      task: {
        customFields: []
      },
      entityOptions: [],
      customActivityFieldMap: {},
      customBookingFieldMap: {},
      customTaskFieldMap: {},

      bookingRestrictedRequiredField: null,
      activityRestrictedRequiredField: null,
      taskRestrictedRequiredField: null,

      isBookingAccessDenied: false,
      isActivityAccessDenied: false,
      isTaskAccessDenied: false
    }
  },
  created() {
    this.resourceUtil = resourceUtil;
    this.projectUtil = projectUtil;
    this.bookingDurationGroup = ['beginDate','untilDate','duration'];
    this.activityDurationGroup = ['startTime','closeTime','duration'];
    this.activityDurationViewGroup = ['startTime','closeTime','estimatedDuration'];
    this.taskDurationGroup = ['startTime','closeTime','duration'];
    this.taskDurationViewGroup = ['startTime','closeTime','estimatedDuration'];
    this.getEntityOptions();
  },
  beforeDestroy() {
    this.bookingDurationGroup = null;
    this.activityDurationGroup = null;
    this.activityDurationViewGroup = null;
    this.taskDurationGroup = null;
    this.taskDurationViewGroup = null;
    this.resourceUtil = null;
    this.projectUtil = null;
  },
  computed: {
    componentId() {
      return `EVENT_TYPE_FORM`
    },
    customActivityFieldsFiltered() {
      return this.activity.customFields.filter(f => this.canView('ACTIVITY', [f.name]));
    },
    customBookingFieldsFiltered() {
      return this.booking.customFields.filter(f => this.canView('BOOKING', [f.name]));
    },
    customTaskFieldsFiltered() {
      return this.task.customFields.filter(f => this.canView('TASK', [f.name]));
    },
    labelTitle() {
      return this.$t('staff.event_type');
    },
    bookingIndex() {
      if (this.isBookingVisible) {
        return 0;
      }
      return -1;
    },
    activityIndex() {
      if (this.isBookingVisible && this.isActivityVisible) {
        return 1;
      }
      else if (this.isActivityVisible) {
        return 0;
      }
      return -1;
    },
    taskIndex() {
      if (this.isBookingVisible && this.isActivityVisible && this.isTaskVisible) {
        return 2;
      }
      else if ((this.isBookingVisible || this.isActivityVisible) && this.isTaskVisible) {
        return 1;
      }
      else if (this.isTaskVisible) {
        return 0;
      }
      return -1;
    },
    vacationIndex() {
      let index = 0;
      if (this.isBookingVisible) {
        index++;
      }
      
      if (this.isActivityVisible) {
        index++;
      }
      
      if (this.isTaskVisible) {
        index++;
      }
      
      if (this.isVacationVisible) {
        return index;
      }
      return -1;
    },
    staffProjectIds() {
      if (this.typeTab === this.taskIndex &&
          this.newEvent.projectTask.length !== 0) {
        return [this.newEvent.projectTask[0].uuId];
      }
      return [];
    },
    isBookingVisible() {
      return (this.mode === 'staff' 
      && this.canView('PROJECT', ['BOOKING'])
      && this.canEdit('PROJECT', ['BOOKING'])
      && this.canView('BOOKING', ['name', 'beginDate', 'untilDate', 'duration', 'STAFF']) 
      && this.canAdd('BOOKING', ['name', 'beginDate', 'untilDate', 'duration'])
      && this.canView('STAFF')
      && this.canEdit('BOOKING', ['STAFF'])) 
      || (this.mode === 'resource' 
      && this.canView('BOOKING', ['name', 'beginDate', 'untilDate', 'duration','RESOURCE'])
      && this.canAdd('BOOKING', ['name', 'beginDate', 'untilDate', 'duration'])
      && this.canView('RESOURCE')
      && this.canEdit('BOOKING', ['RESOURCE']))
    },
    isActivityVisible() {
      return (this.mode === 'staff' 
      && this.canView('ACTIVITY', ['name', 'startTime', 'closeTime', 'estimatedDuration','STAFF']) 
      && this.canAdd('ACTIVITY', ['name', 'startTime', 'closeTime', 'duration'])
      && this.canView('STAFF')
      && this.canEdit('ACTIVITY', ['STAFF'])) 
      || (this.mode === 'resource' 
      && this.canView('ACTIVITY', ['name', 'startTime', 'closeTime', 'estimatedDuration','RESOURCE'])
      && this.canAdd('ACTIVITY', ['name', 'startTime', 'closeTime', 'duration'])
      && this.canView('RESOURCE')
      && this.canEdit('ACTIVITY', ['RESOURCE']))
    },
    isTaskVisible() {
      return (this.mode === 'staff' 
      && this.canView('PROJECT', ['TASK'])
      && this.canEdit('PROJECT', ['TASK'])
      && this.canView('TASK', ['name', 'startTime', 'closeTime', 'estimatedDuration', 'STAFF']) 
      && this.canAdd('TASK', ['name', 'startTime', 'closeTime', 'duration'])
      && this.canView('STAFF')
      && this.canEdit('TASK', ['STAFF'])) 
      || (this.mode === 'resource' 
      && this.canView('TASK', ['name', 'startTime', 'closeTime', 'estimatedDuration', 'RESOURCE'])
      && this.canAdd('TASK', ['name', 'startTime', 'closeTime', 'duration'])
      && this.canView('RESOURCE')
      && this.canEdit('TASK', ['RESOURCE']))
    },
    isVacationVisible() {
      return this.mode !== 'resource' && this.canAdd('CALENDAR') && this.canView('STAFF') && this.canView('CALENDAR')
    },
    isDescriptionVisible() {
      return (this.canView('BOOKING', ['description']) && this.canAdd('BOOKING', ['description']))
      || (this.canView('ACTIVITY', ['description']) && this.canAdd('ACTIVITY', ['description']))
      || (this.canView('TASK', ['description']) && this.canAdd('TASK', ['description']))
    },
    isStageVisible() {
      return this.canView('STAGE') && 
      ((this.typeTab === this.bookingIndex && this.canView('BOOKING', ['STAGE']) && this.canEdit('BOOKING', ['STAGE']))
      || (this.typeTab === this.activityIndex && this.canView('ACTIVITY', ['STAGE']) && this.canEdit('ACTIVITY', ['STAGE']))
      || (this.typeTab === this.taskIndex && this.canView('TASK', ['STAGE']) && this.canEdit('TASK', ['STAGE'])))
    },
    isStageReadOnly() {
      return (this.typeTab === this.bookingIndex && (this.optionBookingStage == null || this.optionBookingStage.length == 0))
      || (this.typeTab === this.activityIndex && (this.optionActivityStage == null || this.optionActivityStage.length == 0))
      || (this.typeTab === this.taskIndex && (this.optionStage == null || this.optionStage.length == 0))
    },
    dataNotReady() {
      return this.newEvent == null || (this.typeTab === this.vacationIndex && this.newEvent.startDate == "" && this.newEvent.closeDate == "") 
      || (this.typeTab === this.bookingIndex &&
          ((this.newEvent.projectBooking == null || this.newEvent.projectBooking.length == 0) 
          || ((this.mode === 'staff' && this.staffs.length == 0) || (this.mode === 'resource' && this.resources.length == 0))))
      || (this.typeTab === this.activityIndex &&
          ((this.activityName == null || this.activityName.trim().length == 0)
          || ((this.mode === 'staff' && this.staffs.length == 0) || (this.mode === 'resource' && this.resources.length == 0))))
      || (this.typeTab === this.taskIndex &&
          ((this.newEvent.projectTask == null || this.newEvent.projectTask.length == 0) 
          || (this.taskName == null || this.taskName.trim().length == 0)
          || ((this.mode === 'staff' && this.staffs.length == 0) || (this.mode === 'resource' && this.resources.length == 0))))
      || (this.typeTab === this.vacationIndex &&
          ((this.vacationName == null || this.vacationName.trim().length == 0)
          || (this.mode === 'staff' && this.staffs.length == 0)))
      
    },
    isAccessDenied() {
      return (this.typeTab === this.bookingIndex && this.isBookingAccessDenied) 
      || (this.typeTab === this.activityIndex && this.isActivityAccessDenied) 
      || (this.typeTab === this.taskIndex && this.isTaskAccessDenied) 
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.alertMsg = null;
        this.alertError = false;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.state.modalShow = newValue;
        if (newValue) {
          const requests = [
            getCustomFieldInfo(this.activity, 'ACTIVITY').catch(e => this.httpAjaxError(e))
            , getCustomFieldInfo(this.booking, 'BOOKING').catch(e => this.httpAjaxError(e))
            , getCustomFieldInfo(this.task, 'TASK').catch(e => this.httpAjaxError(e))
          ]
          Promise.allSettled(requests)
          .finally(() => {
            if (this.activity.customFields.length == 0) {
              this.customActivityFieldMap = {};
            } else {
              // do not allow append after for fields that are not mandatory
              for (const field of this.activity.customFields) {
                if (field.profile.append_after) {
                  const afterField = this.activity.customFields.find(f => f.name === field.profile.append_after);
                  if (afterField && !afterField.notNull) {
                    delete field.profile.append_after; // don't append after a non-visible field
                  }
                }
              }
              this.customActivityFieldMap = getAppendAfterObjectWithTopDownRelationship(this.activity.customFields, this.activityAllowViewFunc);
            }

            if (this.booking.customFields.length == 0) {
              this.customBookingFieldMap = {};
            } else {
              // do not allow append after for fields that are not mandatory
              for (const field of this.booking.customFields) {
                if (field.profile.append_after) {
                  const afterField = this.booking.customFields.find(f => f.name === field.profile.append_after);
                  if (afterField && !afterField.notNull) {
                    delete field.profile.append_after; // don't append after a non-visible field
                  }
                }
              }
              this.customBookingFieldMap = getAppendAfterObjectWithTopDownRelationship(this.booking.customFields, this.bookingAllowViewFunc);

            }
            
            if (this.task.customFields.length == 0) {
              this.customTaskFieldMap = {};
            } else {
              // do not allow append after for fields that are not mandatory
              for (const field of this.task.customFields) {
                if (field.profile.append_after) {
                  const afterField = this.task.customFields.find(f => f.name === field.profile.append_after);
                  if (afterField && !afterField.notNull) {
                    delete field.profile.append_after; // don't append after a non-visible field
                  }
                }
              }
              this.customTaskFieldMap = getAppendAfterObjectWithTopDownRelationship(this.task.customFields, this.taskAllowViewFunc);
            }

            //Determine access permission
            //Booking
            const bookingRequiredFields = ['name', 'beginDate', 'untilDate', 'duration']
            const bookingRequiredCustomFields = this.booking.customFields.filter(i => i.notNull == true).map(i => i.name);
            
            if (bookingRequiredCustomFields.length > 0) {
              bookingRequiredFields.push(...bookingRequiredCustomFields);
            }
            let result = this.canView2('BOOKING', bookingRequiredFields);
            if (result.val) {
              result = this.canAdd2('BOOKING', bookingRequiredFields)
            }
            
            if (this.mode === 'staff') {
              if (result.val && !(this.canView('PROJECT', ['BOOKING']) && this.canEdit('PROJECT', ['BOOKING']))) {
                result = { val: false, restrictedProp: 'PROJECT' }
              }

              if (result.val && !(this.canView('STAFF') && this.canView('BOOKING', ['STAFF']) && this.canEdit('BOOKING', ['STAFF']))) {
                result = { val: false, restrictedProp: 'STAFF' }
              }
            } else if (this.mode === 'resource') {
              if (result.val && !(this.canView('RESOURCE') && this.canView('BOOKING', ['RESOURCE']) && this.canEdit('BOOKING', ['RESOURCE']))) {
                result = { val: false, restrictedProp: 'RESOURCE' }
              }
            }
            
            if (result.restrictedProp != null) {
              this.bookingRestrictedRequiredField = this.getBookingDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isBookingAccessDenied = false;
            } else {
              this.isBookingAccessDenied = true;
            }


            //Activity
            const activityViewRequiredFields = ['name', 'startTime', 'closeTime', 'estimatedDuration']
            const activityAddRequiredFields = ['name', 'startTime', 'closeTime', 'duration']
            const activityRequiredCustomFields = this.activity.customFields.filter(i => i.notNull == true).map(i => i.name);
            if (activityRequiredCustomFields.length > 0) {
              activityViewRequiredFields.push(...activityRequiredCustomFields);
              activityAddRequiredFields.push(...activityRequiredCustomFields);
            }
            result = this.canView2('ACTIVITY', activityViewRequiredFields);
            if (result.val) {
              result = this.canAdd2('ACTIVITY', activityAddRequiredFields)
            }
            
            if (this.mode === 'staff') {
              if (result.val && !(this.canView('STAFF') && this.canView('ACTIVITY', ['STAFF']) && this.canEdit('ACTIVITY', ['STAFF']))) {
                result = { val: false, restrictedProp: 'STAFF' }
              }
            } else if (this.mode === 'resource') {
              if (result.val && !(this.canView('RESOURCE') && this.canView('ACTIVITY', ['RESOURCE']) && this.canEdit('ACTIVITY', ['RESOURCE']))) {
                result = { val: false, restrictedProp: 'RESOURCE' }
              }
            }
            
            if (result.restrictedProp != null) {
              this.activityRestrictedRequiredField = this.getActivityDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isActivityAccessDenied = false;
            } else {
              this.isActivityAccessDenied = true;
            }

            //Task
            const taskViewRequiredFields = ['name', 'startTime', 'closeTime', 'estimatedDuration']
            const taskAddRequiredFields = ['name', 'startTime', 'closeTime', 'duration']
            const taskRequiredCustomFields = this.task.customFields.filter(i => i.notNull == true).map(i => i.name);
            if (taskRequiredCustomFields.length > 0) {
              taskViewRequiredFields.push(...taskRequiredCustomFields);
              taskAddRequiredFields.push(...taskRequiredCustomFields);
            }
            result = this.canView2('TASK', taskViewRequiredFields);
            if (result.val) {
              result = this.canAdd2('TASK', taskAddRequiredFields)
            }
            
            if (this.mode === 'staff') {
              if (result.val && !(this.canView('PROJECT', ['TASK']) && this.canEdit('PROJECT', ['TASK']))) {
                result = { val: false, restrictedProp: 'PROJECT' }
              }

              if (result.val && !(this.canView('STAFF') && this.canView('TASK', ['STAFF']) && this.canEdit('TASK', ['STAFF']))) {
                result = { val: false, restrictedProp: 'STAFF' }
              }
            } else if (this.mode === 'resource') {
              if (result.val && !(this.canView('RESOURCE') && this.canView('TASK', ['RESOURCE']) && this.canEdit('TASK', ['RESOURCE']))) {
                result = { val: false, restrictedProp: 'RESOURCE' }
              }
            }
            
            if (result.restrictedProp != null) {
              this.taskRestrictedRequiredField = this.getTaskDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isTaskAccessDenied = false;
            } else {
              this.isTaskAccessDenied = true;
            }

            //End determining access permission
          });

          
          
          this.newEvent = cloneDeep(this.event);
          
          if (!this.newEvent.customFields) {
            this.newEvent.customFields = {};
          }
          
          for (const field of this.customActivityFieldsFiltered) {
            if (field.notNull) {
              this.newEvent.customFields[field.name] = field.def;
            }
          }
          for (const field of this.customBookingFieldsFiltered) {
            if (field.notNull) {
              this.newEvent.customFields[field.name] = field.def;
            }
          }
          for (const field of this.customTaskFieldsFiltered) {
            if (field.notNull) {
              this.newEvent.customFields[field.name] = field.def;
            }
          }
          
          if (this.profileValues) {
            this.activityName = this.profileValues.activityName;
            this.taskName = this.profileValues.taskName;
            this.vacationName = this.profileValues.vacationName;
            this.typeTab = this.profileValues.typeTab ? this.profileValues.typeTab : 0;
            this.newEvent.projectBooking = cloneDeep(this.profileValues.projectBooking ? this.profileValues.projectBooking : this.newEvent.projectBooking);
            if (this.newEvent.projectBooking && this.newEvent.projectBooking.length > 0) {
              // make sure the project exists
              projectService.get([{ uuId: this.newEvent.projectBooking[0].uuId }],['STAGE', 'STAGE_LIST']).then((response) => {
                if (response.status === 422) {
                  this.newEvent.projectBooking = [];
                }
                else if (response.data.objectList.length > 0) {
                  this.newEvent.projectBooking[0].color = response.data.objectList[0].color;
                  this.newEvent.projectBooking[0].stage = response.data.objectList[0].stage;
                }
              }).catch(() => {
                this.newEvent.projectBooking = [];
              });
            }
            this.newEvent.projectTask = cloneDeep(this.profileValues.projectTask ? this.profileValues.projectTask : this.newEvent.projectTask);
            if (this.newEvent.projectTask && this.newEvent.projectTask.length > 0) {
              // make sure the project exists
              projectService.get([{ uuId: this.newEvent.projectTask[0].uuId }],['STAGE', 'STAGE_LIST']).then((response) => {
                if (response.status === 422) {
                  this.newEvent.projectTask = [];
                }
                else if (response.data.objectList.length > 0) {
                  if (response.data.objectList[0].stageList) {
                    this.project.taskStageList.splice(0, this.project.taskStageList.length, ...response.data.objectList[0].stageList);
                  }
                  else {
                    this.project.taskStageList.splice(0, this.project.taskStageList.length);
                  }
                  this.populateTaskStage();
                  this.newEvent.projectTask[0].color = response.data.objectList[0].color;
                  this.newEvent.projectTask[0].stage = response.data.objectList[0].stage;
                }
              }).catch(() => {
                this.newEvent.projectTask = [];
              });
            }

            const listParams = { start: 0, limit: -1, ksort: 'name' }
            const canViewTag = this.canView('TAG') && this.canView('STAGE', ['TAG']);

            if (canViewTag) {
              const activityListParams = JSON.parse(JSON.stringify(listParams));
              const bookingListParams = JSON.parse(JSON.stringify(listParams));
              if (canViewTag) {
                activityListParams.tagFilter = [['STAGE.TAG.name', 'is', 'ACTIVITY']]
                bookingListParams.tagFilter = [['STAGE.TAG.name', 'is', 'BOOKING']]
              }

              stageService.list(JSON.parse(JSON.stringify(activityListParams))).then(response => {
                if (response.arg_total == 0 && canViewTag && this.entityOptions.length > 0) {
                  const excludedEntities = []
                  for (const entity of this.entityOptions) {
                    excludedEntities.push(['STAGE.TAG.name', 'is', entity.value]);
                  }
                  activityListParams.tagFilter = ['_not_', ['_or_', excludedEntities]]
                  return stageService.list(activityListParams).then(response2 => {
                    if (response2.data) {
                      this.activityStageList.splice(0, this.activityStageList.length, ...response2.data);
                      this.populateActivityStage();
                    } 
                  })
                  .catch(() => {
                    this.activityStageList.splice(0, this.activityStageList.length);
                    this.populateActivityStage();
                  });
                } else {
                  if (response.data) {
                    this.activityStageList.splice(0, this.activityStageList.length, ...response.data);
                    this.populateActivityStage();
                  } 
                }
              }).catch(() => {
                this.activityStageList.splice(0, this.activityStageList.length);
                this.populateActivityStage();
              });

              stageService.list(JSON.parse(JSON.stringify(bookingListParams))).then(response => {
                if (response.arg_total == 0 && canViewTag && this.entityOptions.length > 0) {
                  const excludedEntities = []
                  for (const entity of this.entityOptions) {
                    excludedEntities.push(['STAGE.TAG.name', 'is', entity.value]);
                  }
                  bookingListParams.tagFilter = ['_not_', ['_or_', excludedEntities]]
                  return stageService.list(bookingListParams).then(response2 => {
                    if (response2.data) {
                      this.project.bookingStageList.splice(0, this.project.bookingStageList.length, ...response2.data);
                      this.populateBookingStage();
                    } 
                  })
                  .catch(() => {
                    this.project.bookingStageList.splice(0, this.project.bookingStageList.length);
                    this.populateBookingStage();
                  });
                } else {
                  if (response.data) {
                    this.project.bookingStageList.splice(0, this.project.bookingStageList.length, ...response.data);
                    this.populateBookingStage();
                  } 
                }
              }).catch(() => {
                this.project.bookingStageList.splice(0, this.project.bookingStageList.length);
                this.populateBookingStage();
              });
            } else { //when can't view tag
              stageService.list(listParams).then(response => {
                if (response.data) {
                  this.activityStageList.splice(0, this.activityStageList.length, ...response.data);
                  this.populateActivityStage();
                  this.project.bookingStageList.splice(0, this.project.bookingStageList.length, ...response.data);
                  this.populateBookingStage();
                }
              }).catch(() => {
                this.activityStageList.splice(0, this.activityStageList.length);
                this.populateActivityStage();
                this.project.bookingStageList.splice(0, this.project.bookingStageList.length);
                this.populateBookingStage();
              });
            }

            
    
          }
          
          // save the duration for when the start date is moved greater than the end date
          const startDate = convertDate(this.newEvent.startDate, this.newEvent.startTime);
          const closeDate = convertDate(this.newEvent.closeDate, this.newEvent.closeTime);
          this.duration = closeDate - startDate;
          
          if (this.newEvent.staffUuid) {
            this.staffs = [{ uuId: this.newEvent.staffUuid, name: this.newEvent.staffName, utilization: 1, genericStaff: this.newEvent.genericStaff }];
            this.processCalendar(this.newEvent.staffUuid);
          }
          else {
            this.staffs = [];
          }
          
          if (this.newEvent.resourceUuid) {
            this.resources = [{ uuId: this.newEvent.resourceUuid, name: this.newEvent.resourceName, quantity: 1 }];
          }
          else {
            this.resources = [];
          }
        }
      }
    },
    typeTab() {
      this.dismissAlert();
    }
  },
  methods: {
    getBookingDisplayNameOfProperty(val) {
      if (val == 'PROJECT') {
        return this.$t('booking.field.project');
      } else if (val == 'STAFF') {
        return this.$t('activity.field.staffs');
      } else if (val == 'RESOURCE') {
        return this.$t('task.field.resources');
      } else if (val == 'startTime' || val == 'beginDate') {
        return this.$t('task.field.startTime');
      } else if (val == 'closeTime' || val == 'untilDate') {
        return this.$t('task.field.closeTime');
      } else {
        const found = this.booking.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`booking.field.${val}`);
      }
    },
    getActivityDisplayNameOfProperty(val) {
      if (val == 'STAFF') {
        return this.$t('activity.field.staffs');
      } else if (val == 'RESOURCE') {
        return this.$t('task.field.resources');
      } else if (val == 'startTime') {
        return this.$t('task.field.startTime');
      } else if (val == 'closeTime') {
        return this.$t('task.field.closeTime');
      } else {
        const found = this.activity.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`activity.field.${val}`);
      }
    },
    getTaskDisplayNameOfProperty(val) {
      if (val == 'PROJECT') {
        return this.$t('booking.field.project');
      } else if (val == 'STAFF') {
        return this.$t('activity.field.staffs');
      } else if (val == 'RESOURCE') {
        return this.$t('task.field.resources');
      } else {
        const found = this.task.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`task.field.${val}`);
      }
    },
    async processCalendar(uuId) {
      if (uuId) {
        const calendar = await staffService.calendar(uuId)
        .then((response) => {
          // combine the calendar lists into single lists for each day
          const data = response.data[response.data.jobCase];
          return transformCalendar(processCalendar(data));
        })
        .catch((e) => {
          self.httpAjaxError(e);
          return null;
        });
        
        const start = this.getStartHour(calendar);
        const end = this.getEndHour(calendar);
        this.newEvent.startTime = start;
        this.newEvent.closeTime = end;
      }
    },
    getStartHour(calendar) {
      let startHour = "09:00";
      const day = moment(this.newEvent.startDate).day();
      const WEEKDAY = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      const entry = calendar[WEEKDAY[day]];
      if (entry && entry.length !== 0) {
        if (entry[0].startHour) {
          return entry[0].startHour
        }
      }
      return startHour;
    },
    getEndHour(calendar) {
      let endHour = "17:00";
      const day = moment(this.newEvent.closeDate).day();
      const WEEKDAY = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      const entry = calendar[WEEKDAY[day]];
      if (entry && entry.length !== 0) {
        if (entry[0].endHour) {
          return entry[0].endHour;
        }
      }
      return endHour;
    },
    populateBookingStage() {
      let options = [{key: null, label: ""}]; // First item is blank
      if (this.project.bookingStageList) {
        this.project.bookingStageList.forEach(stage => {
          options.push({key: stage.uuId, label: stage.name ? stage.name : stage});
        });
        this.optionBookingStage = options; 
      }
    },
    populateActivityStage() {
      let options = [{key: null, label: ""}]; // First item is blank
      if (this.activityStageList) {
        this.activityStageList.forEach(stage => {
          options.push({key: stage.uuId, label: stage.name ? stage.name : stage});
        });
        this.optionActivityStage = options; 
      }
    },
    populateTaskStage() {
      let options = [{key: null, label: ""}]; // First item is blank
      if (this.project.taskStageList) {
        this.project.taskStageList.forEach(stage => {
          options.push({key: stage.uuId, label: stage.name ? stage.name : stage});
        });
        this.optionStage = options; 
      }
    },
    staffBadgeRemove: function(index) {
      this.staffs.splice(index,1);
    },
    staffBadgeClick(id) {
      const selected = this.staffs.find(i => i.uuId === id);
      const edit = this.staffUtilizationEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.utilization = selected.utilization;
      edit.unit = selected.quantity;
      edit.genericStaff = selected.genericStaff;
      this.state.staffUtilizationEditShow = true;
    },
    vacationStaffBadgeClick(id) {
      const selected = this.staffs.find(i => i.uuId === id);
      const edit = this.staffUtilizationEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.utilization = selected.utilization;
      edit.unit = selected.quantity;
      edit.genericStaff = selected.genericStaff;
      this.state.staffSelectorShow = true;
    },
    staffUtilizationOk({ id, name, utilization, unit, oldId, genericStaff }) {
      const oldIdIndex = oldId? this.staffs.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.staffs[oldIdIndex];
      } else {
        selected = this.staffs.find(i => i.uuId === id);
      }
      if(selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.utilization = utilization;
        selected.quantity = unit;
        selected.genericStaff = !!genericStaff;
      } else {
        this.staffs.push({
          uuId: id,
          name: name,
          utilization: utilization,
          quantity: unit,
          genericStaff: !!genericStaff
        });
      }
    },
    staffSelectorToggle() {
      this.staffUtilizationEdit = {
        uuId:  null,
        name:  null,
        utilization: null,
        unit: null,
        genericStaff: false
      };
      this.state.staffSelectorShow = true;
    },
    staffSelectorOk({ details }) {
      if (this.staffUtilizationEdit.uuId !== null) {
        // remove the existing staff
        const oldIndex = this.staffs.findIndex(i => i.uuId === this.staffUtilizationEdit.uuId);
        if (oldIndex !== -1) {
          this.staffs.splice(oldIndex, 1);
        }
      }
      
      const newStaffs = details.map(i => { 
        return { 
          uuId: i.uuId
          , name: i.name
          , genericStaff: i.genericStaff 
        }
      });
      
      for (const staff of newStaffs) {
        const newIdIndex = this.staffs.findIndex(i => i.uuId === staff.uuId);
        if(newIdIndex === -1) {
          this.staffs.push({
            uuId: staff.uuId,
            name: staff.name,
            utilization: 1,
            quantity: 1,
            genericStaff: !!staff.genericStaff
          });
        }
      }
    },
    resourceBadgeRemove: function(index) {
      this.resources.splice(index,1);
    },
    resourceBadgeClick(id) {
      const selected = this.resources.find(i => i.uuId === id);
      const edit = this.resourceUnitEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.unit = selected.quantity;
      edit.utilization = selected.utilization;
      this.state.resourceUnitEditShow = true;
    },
    resourceUnitOk({ id, name, unit, utilization, oldId }) {
      const oldIdIndex = oldId? this.resources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.resources[oldIdIndex];
      } else {
        selected = this.resources.find(i => i.uuId === id);
      }
      if (selected != null) {
        selected.uuId = id;
        selected.name = name;
        selected.quantity = unit;
        selected.utilization = utilization;
      }
      else {
        this.resources.push( {uuId: id, name: name, quantity: unit, utilization: utilization} );
      }
    },
    resourceSelectorToggle() {
      this.state.resourceSelectorShow = true;
    },
    resourceSelectorOk({ details }) {
      const newResources = details.map(i => { return { uuId: i.uuId, name: i.name, quantity: 1, utilization: 1.0 }});
      this.resources.splice(0, this.resources.length, ...newResources);
    },
    cancelModal() {
      this.$emit('cancel');
      this.$emit('update:show', false)
    },
    handleOkBtnClicked() {
      if (this.typeTab === this.activityIndex) {
        this.newEvent.type = 'Activity';
        this.newEvent.name = this.activityName;
        this.newEvent.stage = this.activityStage;
        this.newEvent.stageName = this.optionActivityStage.find(b => b.key === this.activityStage).label;
        
        const customFields = this.customActivityFieldsFiltered;
        const keys = [];
        for (const field of customFields) {
          if (field.notNull) { // only validate mandatory fields
            if (!customFieldValidate(field, this.newEvent.customFields[field.name])) {
            field.showError = true;
              return;  
            }
            keys.push(field.name);
          }
        }
        
        if (this.newEvent.customFields != null) {
          for (const key of Object.keys(this.newEvent.customFields)) {
            if (!keys.includes(key)) {
              delete this.newEvent.customFields[key];
            }
          }
        }
      }
      else if (this.typeTab === this.bookingIndex) {
        this.newEvent.type = 'Booking';
        this.newEvent.name = this.newEvent.projectBooking[0].name;
        this.newEvent.stage = this.bookingStage;
        this.newEvent.stageName = this.optionBookingStage.find(b => b.key === this.bookingStage).label;
        
        const custombFields = this.customBookingFieldsFiltered;
        const keysb = [];
        for (const fieldb of custombFields) {
          if (fieldb.notNull &&
              (this.newEvent.customFields[fieldb.name] === null ||
               this.newEvent.customFields[fieldb.name] === '')) {
            return;  
          }
          keysb.push(fieldb.name);
        }
        
        if (this.newEvent.customFields != null) {
          for (const keyb of Object.keys(this.newEvent.customFields)) {
            if (!keysb.includes(keyb)) {
              delete this.newEvent.customFields[keyb];
            }
          }
        }
        
      }
      else if (this.typeTab === this.taskIndex) {
        this.newEvent.type = 'Task';
        this.newEvent.name = this.taskName;
        this.newEvent.stage = this.taskStage;
        this.newEvent.stageName = this.optionStage.find(b => b.key === this.taskStage).label;
        
        const customtFields = this.customTaskFieldsFiltered;
        const keyst = [];
        for (const fieldt of customtFields) {
          if (fieldt.notNull &&
              (this.newEvent.customFields[fieldt.name] === null ||
               this.newEvent.customFields[fieldt.name] === '')) {
            return;  
          }
          keyst.push(fieldt.name);
        }
        
        if (this.newEvent.customFields != null) {
          for (const keyt of Object.keys(this.newEvent.customFields)) {
            if (!keyst.includes(keyt)) {
              delete this.newEvent.customFields[keyt];
            }
          }
        }
      }
      else if (this.typeTab === this.vacationIndex) {
        this.newEvent.type = 'Vacation';
        this.newEvent.name = this.vacationName;
      }
      
      if (this.mode === 'staff') {
        this.newEvent.staffUuid = this.staffs[0].uuId;
        this.newEvent.staffName = this.staffs[0].name;
        this.newEvent.utilization = this.staffs[0].utilization;
        this.newEvent.quantity = this.staffs[0].quantity;
        this.newEvent.multiStaff = this.staffs.length > 1 ? this.staffs : null;
      }
      else if (this.mode === 'resource') {
        this.newEvent.resourceUuid = this.resources[0].uuId;
        this.newEvent.resourceName = this.resources[0].name;
        this.newEvent.quantity = this.resources[0].quantity;
        this.newEvent.utilization = this.resources[0].utilization;
      }

      this.processDateTimeWhenSubmit();
            
      this.$emit('ok', cloneDeep(this.newEvent))
      this.$emit('update:show', false)
    },
    projectBookingBadgeRemove: function(index) {
      this.newEvent.projectBooking.splice(index,1);
    },
    projectTaskBadgeRemove: function(index) {
      this.newEvent.projectTask.splice(index,1);
      this.optionStage.splice(0, this.optionStage.length);
    },
    projectBookingBadgeClick(id) {
      this.projectEdit = id;
      this.state.projectSelectorShow = true;
    },
    projectTaskBadgeClick(id) {
      this.projectEdit = id;
      this.state.projectSelectorShow = true;
    },
    projectSelectorToggle() {
      this.state.projectSelectorShow = true;
    },
    projectSelectorOk({details}) {
      if(details && details.length > 0) {
        if (this.typeTab === this.bookingIndex) {
          this.newEvent.projectBooking = details;
        }
        else if (this.typeTab === this.taskIndex) {
          this.newEvent.projectTask = details;
          this.project.taskStageList.splice(0, this.project.taskStageList.length, ...details[0].stageList);
          this.taskStage = null;
          this.populateTaskStage();
        }
      }
    },
    processDateTimeWhenSubmit() {
      const isStartDateNull = this.newEvent.startDate == null || this.newEvent.startDate.trim().length == 0;
      const isStartTimeNull = this.newEvent.startTime == null || this.newEvent.startTime.trim().length == 0;
      const isCloseDateNull = this.newEvent.closeDate == null || this.newEvent.closeDate.trim().length == 0;
      const isCloseTimeNull = this.newEvent.closeTime == null || this.newEvent.closeTime.trim().length == 0;
      if (isStartDateNull && isCloseDateNull) {
        //Do nothing. Not expecting both dates are null
        return;
      }
      let _startTime = this.newEvent.startTime;
      if (isStartTimeNull) {
        _startTime = '09:00';
      }
      let _closeTime = this.newEvent.closeTime;
      if (isCloseTimeNull) {
        _closeTime = '17:00';
      }

      let _startDate = this.newEvent.startDate;
      if (isStartDateNull) {
        _startDate = this.newEvent.closeDate;
      }

      let _closeDate = this.newEvent.closeDate;
      if (isCloseDateNull) {
        _closeDate = this.newEvent.startDate;
      }

      let startDateMs = convertDate(_startDate, _startTime);
      let closeDateMs = convertDate(_closeDate, _closeTime);
      if (startDateMs > closeDateMs) {
        if (isStartDateNull) {
          _startTime = _closeTime;
          startDateMs = convertDate(_startDate, _startTime);
        } else {
          _closeTime = _startTime;
          closeDateMs = convertDate(_closeDate, _closeTime);
        }
      }

      this.duration = closeDateMs - startDateMs;
      this.newEvent.startDate = _startDate;
      this.newEvent.startTime = _startTime;
      this.newEvent.closeDate = _closeDate;
      this.newEvent.closeTime = _closeTime;
    },


    dateChanged(trigger=null) {
      let _trigger = trigger != null? trigger : 'startDate';

      if (_trigger == 'startDate' || _trigger == 'startTime') {
        if (_trigger == 'startTime') {
          if (this.newEvent.startTime == null || this.newEvent.startTime.trim().length == 0) {
            this.duration = 0;
            return;
          } else if (this.newEvent.startDate == null || this.newEvent.startDate.trim().length == 0) {
            return;
          }
        }  

        let _startTime = this.newEvent.startTime;
        let isStartTimeChanged = false;

        if (_trigger == 'startDate') {
          if (this.newEvent.startDate == null || this.newEvent.startDate.trim().length == 0) {
            this.duration = 0;
            this.$nextTick(() => {
              this.$set(this.newEvent, 'startTime', '');
            });
            return;
          }
          
          if (this.newEvent.startTime == null || this.newEvent.startTime.trim().length == 0) {
            isStartTimeChanged = true;
            _startTime = '09:00';
          }
        }

        const _startDateMs = convertDate(this.newEvent.startDate, _startTime);

        const isCloseDateNull = this.newEvent.closeDate == null || this.newEvent.closeDate.trim().length == 0;
        const isCloseTimeNull = this.newEvent.closeTime == null || this.newEvent.closeTime.trim().length == 0;
        let _closeTime = isCloseTimeNull? '17:00' : this.newEvent.closeTime;
        let _closeDate = this.newEvent.closeDate;
        
        if (isCloseDateNull) {
          _closeDate = this.newEvent.startDate;
        }

        let _closeDateMs = convertDate(_closeDate, _closeTime);
        if (_startDateMs > _closeDateMs) {
          _closeDateMs = convertDate(this.newEvent.startDate, _closeTime);
          this.$nextTick(() => {
            this.duration = 0;
            if (isStartTimeChanged) {
              this.$set(this.newEvent, 'startTime', moment(_startDateMs).format('HH:mm'));  
            }
            this.$set(this.newEvent, 'closeDate', moment(_startDateMs).format('YYYY-MM-DD'));
            this.$set(this.newEvent, 'closeTime', _startDateMs > _closeDateMs? moment(_startDateMs).format('HH:mm') : _closeTime);
          });
        } else {
          this.$nextTick(() => {
            this.duration = _closeDateMs - _startDateMs;
            if (isStartTimeChanged) {
              this.$set(this.newEvent, 'startTime', moment(_startDateMs).format('HH:mm'));  
            }
            this.$set(this.newEvent, 'closeDate', moment(_closeDateMs).format('YYYY-MM-DD'));
            this.$set(this.newEvent, 'closeTime', moment(_closeDateMs).format('HH:mm'));
          });
        }
        return;
      }
      
      if (_trigger == 'closeDate' || _trigger == 'closeTime') {
        if (_trigger == 'closeTime') {
          if (this.newEvent.closeTime == null || this.newEvent.closeTime.trim().length == 0) {
            this.duration = 0;
            return;
          } else if (this.newEvent.closeDate == null || this.newEvent.closeDate.trim().length == 0) {
            return;
          }
        }

        let _closeTime = this.newEvent.closeTime;
        let isCloseTimeChanged = false;

        if (_trigger == 'closeDate') {
          if (this.newEvent.closeDate == null || this.newEvent.closeDate.trim().length == 0) {
            this.duration = 0;
            this.$nextTick(() => {
              this.$set(this.newEvent, 'closeTime', '');
            });
            return;
          }
          
          if (this.newEvent.closeTime == null || this.newEvent.closeTime.trim().length == 0) {
            isCloseTimeChanged = true;
            _closeTime = '17:00';
          }
        }

        const _closeDateMs = convertDate(this.newEvent.closeDate, _closeTime);

        const isStartDateNull = this.newEvent.startDate == null || this.newEvent.startDate.trim().length == 0;
        const isStartTimeNull = this.newEvent.startTime == null || this.newEvent.startTime.trim().length == 0;
        let _startTime = isStartTimeNull? '09:00' : this.newEvent.startTime;
        let _startDate = this.newEvent.startDate;
        
        if (isStartDateNull) {
          _startDate = this.newEvent.closeDate;
        }

        let _startDateMs = convertDate(_startDate, _startTime);
        if (_startDateMs > _closeDateMs) {
          _startDateMs = convertDate(this.newEvent.closeDate, _startTime);

          this.$nextTick(() => {
            this.duration = 0;
            if (isCloseTimeChanged) {
              this.$set(this.newEvent, 'closeTime', _closeTime);  
            }
            this.$set(this.newEvent, 'startDate', moment(_closeDateMs).format('YYYY-MM-DD'));
            this.$set(this.newEvent, 'startTime', _startDateMs > _closeDateMs? moment(_closeDateMs).format('HH:mm') : _startTime);
          });
        } else {
          this.$nextTick(() => {
            this.duration = _closeDateMs - _startDateMs;
            if (isCloseTimeChanged) {
              this.$set(this.newEvent, 'closeTime', _closeTime);  
            }
            this.$set(this.newEvent, 'startDate', moment(_startDateMs).format('YYYY-MM-DD'));
            this.$set(this.newEvent, 'startTime', moment(_startDateMs).format('HH:mm'));
          });
        }
        return;
      }
    },
    getEntityOptions() {
      this.$store.dispatch('data/info', {type: "holder", object: "STAGE,TAG"}).then(value => {
        this.entityOptions.splice(0, this.entityOptions.length);
        for(const v of value) {
          this.entityOptions.push({ value: v, text: v });
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    activityAllowViewFunc(fieldName) {
      return this.canView('ACTIVITY', [fieldName])
    },
    bookingAllowViewFunc(fieldName) {
      return this.canView('BOOKING', [fieldName])
    },
    taskAllowViewFunc(fieldName) {
      return this.canView('TASK', [fieldName])
    },
    httpAjaxError(e) {
      console.error(e); // eslint-disable-line no-console
      const response = e.response;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        this.alertError = true;
      } else {
        this.alertMsg = this.$t('error.internal_server');
        this.alertError = true;
      }
      this.scrollToTop();
    },
    dismissAlert() {
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.alertError = false;
    }
    //End of methods
  }
}
</script>

<style lang="scss" scoped>

.cost-block {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.cost-left {
  margin-right: 15px;
}

.cost-right {
  margin-left: 15px;
}

.image-preview-container {
  width: 100%;
  border: 1px var(--form-control-border) solid;
  padding: 5px;
  min-height: 36px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.image-preview {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 300px;
}

.actual-cost-alert {
  color: var(--status-alt-red);
  position: absolute;
  right: 11px;
  top: 8px;
}
.work-effort-alert {
  color: var(--status-alt-red);
}
.colorRed {
  color: var(--status-alt-red) !important;
}

.rebate-total {
  text-align: right;
  float: right;
  display: inline-block;
}

@media (min-width: 1200px) {
  .remove-padding {
    padding-left: 0;
  }
}
</style>

<style lang="scss">
a.list-group-item {
  border: none !important;
  color: var(--form-control);
  background-color: var(--form-control-bg);
}

.actual-duration-form-control-fix {
  flex: 1 1 auto;
  width: 1%;

  &:not(:last-child) {
    input.form-control {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

@media screen and (max-width: 991px) {
  .identifier-margin {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .color-margin {
    margin-left: 15px;
  }
}

.stage-label-offset label {
  position: relative;
  top: 3px;
}
</style>