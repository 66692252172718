<template>
  <div :id="id" style="width: 100%;">
    
    <b-button-toolbar class="py-1 action-toolbar">
      <b-btn class="action-button" :disabled="readOnly" @click="selectAll">{{ $t('button.selectall') }}</b-btn>
      <b-btn class="action-button" :disabled="readOnly" @click="selectNone">{{ $t('button.selectnone') }}</b-btn>
    </b-button-toolbar>
  
    <b-input-group class="mb-2">
      <b-form-input
        size="md"
        v-model="searchFilter"
        @input="applyFilter"
        :placeholder="$t('dataview.search')">
      </b-form-input>
      <b-input-group-append>
        <b-input-group-text>
          <font-awesome-icon :icon="['far', 'magnifying-glass']"/>
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    
    <ag-grid-vue style="width: 100%;" :style="[height != null? { height: `${height}px` }: {}]" class="ag-theme-balham permission-grid-height checklist-grid" id="contact-grid"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        animateRows
        :autoGroupColumnDef="autoGroupColumnDef"
        :columnDefs="columnDefs"
        :context="context"
        :defaultColDef="defaultColDef"
        noRowsOverlayComponent="noRowsOverlay"
        :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :getDataPath="data => data.path"
        :getRowId="params => params.data.uuId"
        groupDefaultExpanded
        :rowData="rowData"
        :rowSelection="multiple? 'multiple':'single'"
        rowMultiSelectWithClick
        :sideBar="false"
        suppressDragLeaveHidesColumns
        suppressCellFocus
        suppressContextMenu=true
        suppressMultiSort
        :suppressRowClickSelection="readOnly"
        treeData
        >
    </ag-grid-vue>
    
    <PropertiesPermissionModal :show.sync="showPermissionEdit" :macros="macros" :schema="schema" :isAdd="isPermissionAdd" :isEdit="isPermissionEdit" :entity="permissionEntity" :denyRules="denyRules" @success="permissionSelectOk" :readOnly="readOnly"/>
    <DataPermissionModal :show.sync="showDataPermissionEdit" :macros="macros" :schema="schema" :staffRuleApplied="staffRuleApplied" :projectRuleApplied="projectRuleApplied" :isAdd="isPermissionAdd" :isEdit="isPermissionEdit" :entity="permissionEntity" :dataRules="dataRules" @success="dataPermissionSelectOk" :readOnly="readOnly"/>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { strRandom } from '@/helpers';
import { permissionService, managementService } from '@/services';
import PermissionLabelCellRenderer from '@/components/Aggrid/CellRenderer/PermissionLabel';
import PermissionButtonCellRenderer from '@/components/Aggrid/CellRenderer/PermissionButton';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

export default {
  name: 'PermissionList',
  components: {
    'ag-grid-vue': AgGridVue,
    /* eslint-disable vue/no-unused-components */
    'permissionLabelCellRenderer': PermissionLabelCellRenderer,
    'permissionButtonCellRenderer': PermissionButtonCellRenderer,
    'noRowsOverlay': NoRowsOverlay,
    /* eslint-enable vue/no-unused-components */
    PropertiesPermissionModal: () => import('@/components/modal/PropertiesPermissionModal'),
    DataPermissionModal: () => import('@/components/modal/DataPermissionModal')
    
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: 'Contacts'
    },
    permissionIds: {
      type: Array,
      default: () => []
    },
    permissionProperties: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: null      
    },
    isOwnUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: `PERMISSION_LIST_${strRandom(5)}`,
      gridOptions: null,
      gridApi: null,
      autoGroupColumnDef: null,
      columnDefs: [],
      context: null,
      defaultColDef: null,
      rowData: null,
      
      permissionsTemplate: [],
      state: {
        dataUpdating: false
      },
      showPermissionEdit: false,
      showDataPermissionEdit: false,
      permissionEntity: null,
      isPermissionEdit: false,
      isPermissionAdd: false,
      permissionObject: null,
      schema: {},
      macros: {},
      whitelist: {},
      denyRules: [],
      dataRules: [],
      permissionPropertiesData: [],
      searchFilter: '',

      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      staffRuleApplied: false,
      projectRuleApplied: false
    }
  },
  beforeMount() {
    const self = this;
    this.permissionPropertiesData = this.permissionProperties;
    this.gridOptions = {
      isExternalFilterPresent: function() {
        return self.searchFilter.length > 0;
      },
      doesExternalFilterPass: function doesExternalFilterPass(node) {
        if (node.data) {
          return node.data.name.toLowerCase().indexOf(self.searchFilter.toLowerCase()) !== -1 ||
          node.data.label.toLowerCase().indexOf(self.searchFilter.toLowerCase()) !== -1;
        }
        return false;
      },
      rowHeight: 30,
      onSelectionChanged: debounce((event) => {
        if(!this.state.dataUpdating) {
          this.$emit('selected', event.api.getSelectedNodes().map(i => {
            if (typeof i.data !== 'undefined') { 
              return { uuId: i.data.uuId, name: i.data.name, path: i.data.path, denyRules: i.data.denyRules }; 
            }
            
          }));
          event.api.redrawRows({rowNodes: event.api.getSelectedNodes()});
        }
      }, 300),
      onColumnVisible: function(event) {
        event.api.sizeColumnsToFit();
      },
      onRowSelected: function(event) {
        if (!event.data) {
          return;
        }
        var name = event.data.name;
        const tokens = name.split(self.delimiter);
        const len = tokens.length;
        const label = tokens.slice(len-1, len);

        if (name.startsWith('CALENDAR')) {
          // If we turn on the CALENDAR__EDIT permission, turn on the ADD and DELETE
          // rules as well since both take place in normal calendar editing.
          if (name == 'CALENDAR__EDIT' && event.node.selected) {
            const calAdd = 'CALENDAR__ADD';
            const calDel = 'CALENDAR__REMOVE';
            event.api.forEachLeafNode(function(rowNode) {
              if (rowNode.data.name == calAdd || rowNode.data.name == calDel) {
                rowNode.setSelected(true);
              }
            });
          } else if ((name == "CALENDAR__ADD" || name == "CALENDAR__REMOVE") && !event.node.selected) {
            event.api.forEachLeafNode(function(rowNode) {
              if (rowNode.data.name == "CALENDAR__EDIT") {
                if (rowNode.selected) {
                  // Turn off
                  rowNode.setSelected(false);
                }
              }
            });
          }
        } else {
          // If the row we selected is an __EDIT, also enable the corresponding __VIEW for it
          if (label == 'EDIT' && event.node.selected) {
            const editPerm = tokens.join('__');
            tokens[len-1] = 'VIEW';
            const viewPerm = tokens.join('__');
            // Find this exact permission and enable it
            event.api.forEachLeafNode(function(rowNode) {
              if (rowNode.data.name == viewPerm) {
                rowNode.setSelected(true);
              }
            });
          }
          if (label == 'VIEW' && !event.node.selected) {
            // If we are unselecting a __VIEW, prevent it if the __EDIT is also on
            tokens[len-1] = 'EDIT';
            const editPerm = tokens.join('__');
            event.api.forEachLeafNode(function(rowNode) {
              if (rowNode.data.name == editPerm) {
                if (rowNode.selected) {
                  // Turn back off
                  rowNode.setSelected(false);
                }
              }
            });
          }

          // If the row we selected is an __ADD, also enable the corresponding __EDIT for it
          if (label == 'ADD' && event.node.selected && !self.state.dataUpdating) {
            tokens[len-1] = 'EDIT';
            const editPerm = tokens.join('__');
            tokens[len-1] = 'VIEW';
            const viewPerm = tokens.join('__');
            tokens[len-1] = 'ADD';
            const addPerm = tokens.join('__');
            // Find this exact permission and enable it
            event.api.forEachLeafNode(function(rowNode) {
              if (rowNode.data.name == editPerm) {
                rowNode.setSelected(true);
              }
            });
          }
          if (label == 'EDIT' && !event.node.selected) {
            // If we are unselecting a __EDIT, prevent it if the __ADD is also on
            tokens[len-1] = 'ADD';
            const editPerm = tokens.join('__');
            event.api.forEachLeafNode(function(rowNode) {
              if (rowNode.data.name == editPerm) {
                if (rowNode.selected) {
                  // Turn back off
                  rowNode.setSelected(false);
                }
              }
            });
          }
        }
      }
    };
    this.autoGroupColumnDef = {
      headerName: 'Permissions',
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true,
        checkbox: params => {
          return params.node.group === false;
        },
        isReadOnly: () => self.readOnly == true,
        innerRenderer: 'permissionLabelCellRenderer',
      },
      sortingOrder: [null],
      sort: null
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 80,
      hide: true,
      menuTabs: []
    };
    
    this.columnDefs = [{
      cellRenderer: 'permissionButtonCellRenderer',
      minWidth: 100,
      hide: false,
      sortable: false
    }];
    
    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });
  },
  watch: {
    readOnly() {
      //Force autoGroupColumn cell refresh to reflect the readOnly change
      if (this.gridApi) {
        this.gridApi.refreshCells({ force: true });
      }
    }
  },
  async created() {
    this.delimiter = '__';
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectOrientationChange);
    this.delimiter = null;
    this.gridApi = null;
  },
  computed: {
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
  },
  methods: {
    async loadSchema() {
      this.schema = await managementService.info({ type: 'api', opts: 'basic' }).then((response) => { 
        return response.data[response.data.jobCase];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      this.macros = await this.$store.dispatch('data/info', { type: 'macro' }).then((value) => { 
        return value;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    label(params) {
      return params.data? params.data.label: this.$t(`permission.${params.value.toLowerCase()}`);
      // return params.data? this.$t(`permission.${params.data.label.toLowerCase()}`): this.$t(`permission.${params.value.toLowerCase()}`);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      const self = this;
      this.permissionTemplateGet(() => {
        self.rowData = self.permissionsTemplate || [];
        this.detectOrientationChange();
        this.$nextTick(() => {
          this.state.dataUpdating = true;
          this.permissionIds.forEach(i => {
            const selected = this.gridApi.getRowNode(i);
            if(selected) {
              selected.setSelected(true);
            }
          });
          this.gridApi.forEachNode((node/*, index */) => {
            if(node.group) {
              node.setExpanded(true);
            }
          })
          setTimeout(() => {
            this.state.dataUpdating = false;
          }, 500);
        });
      });
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.gridApi) {
          this.gridApi.sizeColumnsToFit();  
        }
      }, 100);
    },
    async permissionTemplateGet(callback) {
      
      this.whitelist = await this.$store.dispatch('data/whitelist',{ type: 'permission', object: 'MODELS' }).then((value) => { 
        return value.reduce(function(obj, cur /**, i */) {
          obj[cur] = cur;
          return obj;
        }, {});
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
      });
      
      await this.loadSchema();
      
      await permissionService.list({ start: 0, limit: -1 })
      .then(response => {
        const pList = response.data[response.data.jobCase];
        this.permissionsTemplate = pList.filter(i => i.uuId != null).map(i => {
          const tokens = i.name.split(this.delimiter);
          // Handling for adding API tree node
          if (i.name === 'AUTHORING_AS_ALIAS_USER') {
            tokens.unshift('API');
          }
          const len = tokens.length;
          const label = tokens.slice(len-1, len);
          const props = this.permissionPropertiesData.filter(p => p.name === i.name);
          const denyRules = props.length > 0 && props[0].permissionLink ? props[0].permissionLink.denyRules : 
                            (label[0] === 'ADD' || label[0] === 'EDIT' || label[0] === 'VIEW') ? [] : null;
          const dataRules = props.length > 0 && props[0].permissionLink ? props[0].permissionLink.dataRules : 
                            (label[0] === 'EDIT' || label[0] === 'VIEW') ? [] : null;
          const _label = label.length > 0? this.$t(`permission.${label[0].toLowerCase()}`): 'N/A'
          const sortKey = `${tokens.slice(0, len-1).join('_')}__${_label}`

          if (this.canDeny({ path: tokens }) && denyRules) {
            if (label[0] !== 'ADD') {
              return {
                uuId: i.uuId, name: i.name, path: tokens, label: _label, sortKey, denyRules: denyRules, dataRules: dataRules ? dataRules : []
              }
            }
            else {
              return {
                uuId: i.uuId, name: i.name, path: tokens, label: _label, sortKey, denyRules: denyRules
              }
            }
          }
          else {
            if (label[0] === 'EDIT' || label[0] === 'VIEW') {
              return {
                uuId: i.uuId, name: i.name, path: tokens, label: _label, sortKey, dataRules: dataRules ? dataRules : []
              }
            }
            else {
              return {
                uuId: i.uuId, name: i.name, path: tokens, label: _label, sortKey
              }
            }
          }
        });
        this.permissionsTemplate.sort(function(a, b) {
          if ((a == null || a.sortKey == null) && (b == null || b.sortKey == null)) {
            return 0;
          }
          if (a == null || a.sortKey == null) {
            return -1;
          }
          if (b == null || b.sortKey == null) {
            return 1;
          }
          return a.sortKey.localeCompare(b.sortKey)
        })
        callback();
      })
      .catch(e => {
        this.permissionsTemplate = [];
        if (e.response && 403 === e.response.status) {
          this.showNoRowsOverlay(this.$t('entity_selector.error.insufficient_permission_to_show_data'));
        } else {
          this.showNoRowsOverlay(this.$t('error.grid_data_loading'));
        }
        callback();
      });
    },
    canDeny(data) {
      if (this.whitelist && data) {
        const whitelisted = this.whitelist[data.path[0]];
        if (data.path[data.path.length - 2] === 'CALENDAR' ||
            (!whitelisted || !this.schema[data.path[0]])) {
        
          return false;
        }
      }
      return data && (data.path[data.path.length - 1] === 'EDIT' || data.path[data.path.length - 1] === 'ADD' || data.path[data.path.length - 1] === 'VIEW');
    },
    selectAll() {
      const self = this;
      // clear out the denyRules on selectAll so that all properties are selected
      this.gridApi.forEachNode(function(node) {
        if (typeof node.data !== 'undefined' &&
           (self.searchFilter === '' || node.data.name.toLowerCase().indexOf(self.searchFilter.toLowerCase()) !== -1)) {
          node.setSelected(true);
          if (!node.group &&
              self.canDeny(node.data)) {
            node.data.denyRules = [];
          }
        }
      });
      
      for (const p of this.permissionPropertiesData) {
        p.permissionLink = { denyRules: [] };
      }
      
      this.$emit('selected', this.gridApi.getSelectedNodes().map(i => {
        if (typeof i.data !== 'undefined') { 
          return { uuId: i.data.uuId, name: i.data.name, path: i.data.path, denyRules: i.data.denyRules }; 
        }
        
      }));
    },
    selectNone() {
      this.gridApi.forEachNode(function(node) {
        node.setSelected(false);
      });
    },
    editPermission(params) {
      this.permissionEntity = params.data.path[params.data.path.length - 2];
      this.isPermissionEdit = params.data.name.indexOf('EDIT') !== -1;
      this.isPermissionAdd = params.data.name.indexOf('ADD') !== -1;
      this.permissionObject = params.data;
      this.denyRules = params.data.denyRules;
      this.showPermissionEdit = true;
    },
    editDataPermission(params) {
      this.permissionEntity = params.data.path[params.data.path.length - 2];
      this.isPermissionEdit = params.data.name.indexOf('EDIT') !== -1;
      this.isPermissionAdd = params.data.name.indexOf('ADD') !== -1;
      this.permissionObject = params.data;
      this.dataRules = params.data.dataRules;
      this.staffRuleApplied = false;
      this.projectRuleApplied = false;
      
      if (this.permissionEntity === 'CUSTOMER') {
        const prj = this.rowData.filter(p => p.name === 'PROJECT__VIEW' ||
                                           p.name === 'PROJECT__EDIT' ||
                                           p.name === 'PROJECT__REMOVE');
        for (const project of prj) {
          if (project.dataRules && 
              project.dataRules.length !== 0 &&
              !project.dataRules[0].startsWith('.CUSTOMER')) {
            this.projectRuleApplied = true;
            break;   
          }
        }
      }
      
      if (this.permissionEntity === 'DEPARTMENT') {
        const stf = this.rowData.filter(p => p.name === 'STAFF__VIEW' ||
                                           p.name === 'STAFF__EDIT' ||
                                           p.name === 'STAFF__REMOVE');
        for (const staff of stf) {
          if (staff.dataRules &&
              staff.dataRules.length !== 0 &&
              !staff.dataRules[0].startsWith('.DEPARTMENT')) {
            this.staffRuleApplied = true;
            break;   
          }
        }
      }
      
      this.showDataPermissionEdit = true;
    },
    applyRules(data, props, list, entities = true) {
      if (!data.denyRules) {
        data.denyRules = [];
      }
      
      if (props.length > 0) {
        if (!props[0].permissionLink) {
          props[0]['permissionLink'] = { denyRules: [] };
        }
        else {
          data.denyRules = props[0].permissionLink.denyRules;
        }
      }
      
      const entity = data.path[0];
      var schemaObj = this.schema[entity];
      for (const l of list) {
        const propertyIndex = schemaObj.properties.findIndex(p => p.field === l);
        const propertyReadOnly = schemaObj.properties[propertyIndex] ? schemaObj.properties[propertyIndex].readOnly : false;
        if (!propertyReadOnly && data.denyRules.findIndex(d => d === l) === -1 &&
            ((entities && l === l.toUpperCase()) || l !== l.toUpperCase())) {
          data.denyRules.push(l);
          if (props.length > 0 &&
              props[0].permissionLink.denyRules.findIndex(d => d === l) === -1) {
            props[0].permissionLink.denyRules.push(l);
          }
        }
      }
    },
    dataPermissionSelectOk(perms) {
      this.permissionObject.dataRules = perms;
      
      const entityRows = this.rowData.filter(f => f.name === `${this.permissionEntity}__EDIT` ||
                                            f.name === `${this.permissionEntity}__REMOVE`); 
      for (const entityRow of entityRows) {
        if (entityRow.dataRules) {
          if (perms.length !== 0) {
            entityRow.dataRules.splice(0, 1, `${perms[0]}`);
          }
          else if (entityRow.dataRules.length === 1) {
            entityRow.dataRules.splice(0, 1);
          }
        }
      }
      
      if (this.permissionEntity === 'PROJECT') {
        // apply to tasks and bookings also
        const rows = this.rowData.filter(f => f.name === 'TASK__VIEW' || 
                                              f.name === 'TASK__EDIT' || 
                                              f.name === 'TASK__REMOVE' || 
                                              f.name === 'BOOKING__VIEW' || 
                                              f.name === 'BOOKING__EDIT' || 
                                              f.name === 'BOOKING__REMOVE');
        for (const row of rows) {
          if (row.dataRules) {
            if (perms.length !== 0) {
              row.dataRules.splice(0, 1, `.PROJECT.${perms[0]}`);
            }
            else if (row.dataRules.length === 1 && row.dataRules[0].startsWith('.PROJECT')) {
              row.dataRules.splice(0, 1);
            }
          }
        }
      }
      else if (this.permissionEntity === 'CUSTOMER') {
        // apply to project
        const prj = this.rowData.filter(p => p.name === 'PROJECT__VIEW' ||
                                           p.name === 'PROJECT__EDIT' ||
                                           p.name === 'PROJECT__REMOVE');
        for (const project of prj) {
          if (project.dataRules) {
            if (perms.length !== 0) {
              project.dataRules.splice(0, 1, `.CUSTOMER.${perms[0]}`);
            }
            else if (project.dataRules.length === 1 && project.dataRules[0].startsWith('.CUSTOMER')) {
              project.dataRules.splice(0, 1);
            }
          }
        }
                
        // apply to tasks and bookings also
        const rows = this.rowData.filter(f => f.name === 'TASK__VIEW' || 
                                              f.name === 'TASK__EDIT' || 
                                              f.name === 'TASK__REMOVE' || 
                                              f.name === 'BOOKING__VIEW' || 
                                              f.name === 'BOOKING__EDIT' || 
                                              f.name === 'BOOKING__REMOVE');
        for (const row of rows) {
          if (row.dataRules) {
            if (perms.length !== 0) {
              row.dataRules.splice(0, 1, `.PROJECT.CUSTOMER.${perms[0]}`);
            }
            else if (row.dataRules.length === 1 && row.dataRules[0].startsWith('.PROJECT.CUSTOMER')) {
              row.dataRules.splice(0, 1);
            }
          }
        }
      }
      else if (this.permissionEntity === 'DEPARTMENT') {
        // apply to staff
        const stf = this.rowData.filter(p => p.name === 'STAFF__VIEW' ||
                                           p.name === 'STAFF__EDIT' ||
                                           p.name === 'STAFF__REMOVE');
        for (const s of stf) {
          if (s.dataRules) {
            if (perms.length !== 0) {
              s.dataRules.splice(0, 1, `.DEPARTMENT.${perms[0]}`);
            }
            else if (s.dataRules.length === 1 && s.dataRules[0].startsWith('.DEPARTMENT')) {
              s.dataRules.splice(0, 1);
            }
          }
        }
      }
            
      this.$emit('selected', this.gridApi.getSelectedNodes().map(i => {
        if (typeof i.data !== 'undefined') { 
          return { uuId: i.data.uuId, name: i.data.name, path: i.data.path, denyRules: i.data.denyRules, dataRules: i.data.dataRules }; 
        }
        
      }));
    },
    permissionSelectOk(list) {
      const self = this;
      if (this.permissionObject.denyRules) {
        this.permissionObject.denyRules.splice(0, this.permissionObject.denyRules.length, ...list);
      }
      else {
        this.$set(this.permissionObject, 'denyRules', list);
      }
      
      // we need to uncheck edit permissions if view permissions are off
      if (this.permissionObject.name.endsWith('__VIEW')) {
        const name = `${this.permissionObject.path[this.permissionObject.path.length - 2]}__EDIT`;
        const nameAdd = `${this.permissionObject.path[this.permissionObject.path.length - 2]}__ADD`;
        const props = this.permissionPropertiesData.filter(p => p.name === name);
        const propsAdd = this.permissionPropertiesData.filter(p => p.name === nameAdd);
      
        this.gridApi.forEachNode(function(node) {
          if (typeof node.data !== 'undefined' && node.data.name === name) {
            self.applyRules(node.data, props, list);
          }
        });
      
        this.gridApi.forEachNode(function(node) {
          if (typeof node.data !== 'undefined' && node.data.name === nameAdd) {
            self.applyRules(node.data, propsAdd, list, false);
          }
        });
      }
      // we need to check view permissions if view permissions are off
      else if (this.permissionObject.label === 'EDIT') {
        const nameview = `${this.permissionObject.path[this.permissionObject.path.length - 2]}__VIEW`;
        const propsview = this.permissionPropertiesData.filter(p => p.name === nameview);
        if (propsview.length !== 0) {
          this.gridApi.forEachNode(function(node) {
            if (typeof node.data !== 'undefined' && node.data.name === nameview) {
              
              if (propsview[0].permissionLink) {
                if (!node.data.denyRules) {
                  node.data.denyRules = propsview[0].permissionLink.denyRules;
                }
                // const entity = node.data.path[0];
                // var schemaObj = self.schema[entity];
                for (var i = node.data.denyRules.length - 1; i >= 0; i--) {
                  const l = node.data.denyRules[i];
                  const index = list.findIndex(d => d === l);
                  if (index === -1) {
                    node.data.denyRules.splice(index, 1);
                    if (propsview[0].permissionLink.denyRules) {
                      propsview[0].permissionLink.denyRules.splice(index, 1);
                    }
                  }
                }
              }
            }
          });
        }
      }
      // we need to check view permissions if view permissions are off
      else if (this.permissionObject.label === 'ADD') {
        const addnameview = `${this.permissionObject.path[this.permissionObject.path.length - 2]}__VIEW`;
        const addpropsview = this.permissionPropertiesData.filter(p => p.name === addnameview);
        if (addpropsview.length !== 0) {
          this.gridApi.forEachNode(function(node) {
            if (typeof node.data !== 'undefined' && node.data.name === addnameview) {
              
              if (addpropsview[0].permissionLink) {
                if (!node.data.denyRules) {
                  node.data.denyRules = addpropsview[0].permissionLink.denyRules;
                }
                // const entity = node.data.path[0];
                // var schemaObj = self.schema[entity];
                for (var i = node.data.denyRules.length - 1; i >= 0; i--) {
                  const l = node.data.denyRules[i];
                  const index = list.findIndex(d => d === l);
                  if (index === -1) {
                    node.data.denyRules.splice(index, 1);
                    if (addpropsview[0].permissionLink.denyRules) {
                      addpropsview[0].permissionLink.denyRules.splice(index, 1);
                    }
                  }
                }
              }
            }
          });
        }
      }
      
      this.$emit('selected', this.gridApi.getSelectedNodes().map(i => {
        if (typeof i.data !== 'undefined') { 
          return { uuId: i.data.uuId, name: i.data.name, path: i.data.path, denyRules: i.data.denyRules, dataRules: i.data.dataRules }; 
        }
        
      }));
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      
      this.gridApi.onFilterChanged();
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('permission.grid_no_data');
    },
    showNoRowsOverlay(msg=null) {
      this.noRowsMessage = msg
      if (this.gridApi != null) {
        this.gridApi.hideOverlay()
        setTimeout(() => {
          this.gridApi.showNoRowsOverlay()
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.permission-grid-height {
  height: 500px;
  min-height: 350px;
}

.action-button {
  margin: 4px;
}

::v-deep .ag-theme-balham .ag-row-selected::before {
  background-color: transparent;
}

</style>