var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.shouldShow
        ? _c(
            "b-button",
            {
              staticClass: "fields-button",
              attrs: {
                disabled: _vm.disableButtons,
                id: `BTN_FIELD_PERMISSIONS_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                size: "sm",
              },
              on: { click: _vm.edit },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "pen-field"] },
              }),
              _vm.params &&
              _vm.params.data.denyRules &&
              _vm.params.data.denyRules.length !== 0
                ? _c("span", { staticClass: "changed-dot" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldShow
        ? _c("b-popover", {
            attrs: {
              target: `BTN_FIELD_PERMISSIONS_${_vm.params.eGridCell.getAttribute(
                "comp-id"
              )}`,
              placement: "top",
              boundary: "viewport",
              triggers: "hover",
              content: _vm.$t("permission.field_permissions"),
            },
          })
        : _vm._e(),
      _vm.shouldShowData
        ? _c(
            "b-button",
            {
              staticClass: "ml-2 fields-button",
              attrs: {
                disabled: _vm.disableButtons,
                id: `BTN_DATA_PERMISSIONS_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                size: "sm",
              },
              on: { click: _vm.editData },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["far", "list"] } }),
              _vm.params &&
              _vm.params.data.dataRules &&
              _vm.params.data.dataRules.length !== 0
                ? _c("span", { staticClass: "changed-dot" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldShowData
        ? _c("b-popover", {
            attrs: {
              target: `BTN_DATA_PERMISSIONS_${_vm.params.eGridCell.getAttribute(
                "comp-id"
              )}`,
              placement: "top",
              boundary: "viewport",
              triggers: "hover",
              content: _vm.$t("permission.data_permissions"),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }